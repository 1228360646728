import React from 'react';

import './LandingHeader.css';


const LandingHeader = (props) => { // Using props directly
    return (
        // container

        <div className="landing-header">{props.title}
            </div>

    );
}

export default LandingHeader;