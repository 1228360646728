import React from 'react';
import './Timeline.css';
import { Container, Row, Col } from 'react-bootstrap';
import '../styles.css';

const Timeline = () => {
  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs={12} sm={8} lg={6}>
          <div className="section_heading text-center">
            <h3>Timeline</h3>
            <div className="line"></div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} lg={12}>
          <div className="apland-timeline-area">
            {/* Timeline Item */}
            <div className="single-timeline-area">
              <div className="timeline-date wow fadeInLeft" data-wow-delay="0.1s">
                <p>Near term</p>
              </div>
              <Row>
                <Col xs={12} md={12} lg={6}>
                  <div className="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.5s">
                    <div className="timeline-text">
                      <h3>Data Driven Heat Loss Surveys</h3>
                      <p>Using servø's data-driven heat loss tooling, we can place our sensor on an existing fossil fuel boiler and deliver the insights you need to size a heat pump.</p>
                      <br></br>
                      <p>We'll be able to accurately calculate the heat loss of a building and provide insights based on real user data, not assumptions hidden within a calculator.</p>
                      <br></br>
                      <p>We're already engaged with industry leading engineers and installers to help develop this service, so we can remove some of the existing barriers to heat pump sizing and installation.</p>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={12} lg={6}>
                  <div className="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.5s">
                    <div className="timeline-text">
                      <h3>Thermal Modelling of Buildings</h3>
                      <p>Building on the insights from the heat loss surveys, we will be able to model the thermal performance of buildings.</p>
                      <br></br>
                      <p>This will allow us to provide recommendations on how to improve performance of the heating system and provide insights into control strategies to suit the fabric of the building.</p>
                      <br></br>
                      <p>We're building something which can characterise each individual building's unique thermal properties. This will then be leveraged in the
                        next steps towards a fully optimised heating system.</p>
                    </div>
                  </div>
                </Col>
                
              </Row>
            </div>
            {/* Additional timeline items can be added here following the same structure */}
            <div className="single-timeline-area">
              <div className="timeline-date wow fadeInLeft" data-wow-delay="0.1s">
                <p>Medium term</p>
              </div>
              <Row>
                <Col xs={12} md={12} lg={6}>
                  <div className="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.5s">
                    <div className="timeline-text">
                      <h3>Heat Pump Control</h3>
                      <p>Our industry-leading experts have built their careers on heating and energy storage. We're uniquely positioned to build the future of smart heat.</p>
                      <br></br>
                      <p>Armed with the knowledge from the heat loss surveys and thermal modelling, we will be able to control a heating systems to match the prefered outcomes of the occupants.</p>
                      <br></br>
                       <p>Every household has different preferences, and so the heating system should be able to adapt to these preferences.</p>
                    </div>
                  </div>
                </Col>
              </Row>
              </div>
              <div className="single-timeline-area">
              <div className="timeline-date wow fadeInLeft" data-wow-delay="0.1s">
                <p>Long Term</p>
              </div>
              <Row>
                <Col xs={12} md={12} lg={6}>
                  <div className="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.5s">
                    <div className="timeline-text">
                      <h3>Heat Optimisation</h3>
                      <p>The future of heat is all about flexibility.</p>
                      <br></br>
                      <p>With the knowledge gained from the heat loss surveys, thermal modelling and control strategies, we will be able to provide a complete thermal optimisation service which will sit within the 
                        wider electricity market.</p>
                      <br></br>
                      <p>The outcome will be a fully optimised efficient heating system delivering thermal comfort to the occupants with simplicity, whilst also navigating market signals in order to be sympathetic to the wider electricity system.</p>
                    </div>
                  </div>
                </Col>
              </Row>
              </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Timeline;
