export const getData = `
  query getData($device_id: ID!, $date: String) {
    getData(device_id: $device_id, date: $date) {
      time
      flow_temperature
      return_temperature
      room_temperature
      room_humidity
    }
  }
`;
export const getDevices = `
  query {
    getDevices {
      device_id
    }
  }
`;


export const getDevice = `
  query getDevice($device_id: ID!) {
    getDevice(device_id: $device_id) {
      friendly_name
      description
    }
  }
`;

export const getPower = `
  query getPower($device_id: ID!, $date: String) {
    getPower(device_id: $device_id, date: $date) {
      time
      heat_power
    }
  }
`;
