// BlogPosts.js
import { EnergyUseChart } from './energyUseChart';
import { Row, Col, Container } from 'react-bootstrap';

const BlogHeader = (props) => (
  <Container style={{ width: '100%', marginTop: '2rem', marginBottom: '2rem', textAlign: 'center' }}>
    <div>
      <h1>{props.title}</h1>
      <h4>Author: {props.author}</h4>
    </div>
  </Container>
);

const BlogFooter = () => (
  <Container style={{ width: '100%', marginTop: '2rem', marginBottom: '2rem', textAlign: 'center' }}>
    <div>
      <h3>Servo is making <b>clean heat simple</b></h3>
    </div>
  </Container>
);

const Post1 = () => (
  <div>
    <br />
    <h1><b><BlogHeader title="The Art of Execution: 'How' Matters More Than 'What'" author="James Twallin" /></b></h1>
<hr></hr>
<p>Validation of ideas is a critical part of the innovation process. It's the stage where we test our assumptions and learn from our experiments.</p>
<p>A lot of companies are good at generating ideas, but not so good at executing them.</p>
<p>My belief is that if you constantly have to wait for people higher up in the company structure to give people the 'go-ahead' to do things, you simply won't be able to innovate.
  Not only that, you'll create a mental health crisis in the workforce because you're killing their self-belief by taking away their autonomy.
  Without explicitly doing it, you're removing a large part of what makes people feel good about themselves: self-belief.</p>
<h3>Asking for approval kills self belief</h3>
<hr></hr>
<p><b>The renewables sector is filled with people who are passionate about the environment and want to make a difference</b>. It's a fact that people care deeply about their jobs in this industry.</p>
<p>People who love their jobs allow themselves (for better or worse) to be defined by their work. They want to do a good job, and they want to be proud of what they do.</p>
<p>In my experience, the best way to kill this passion is to take away autonomy and force people to ask for permission to do things.</p>
<p>When you have to ask for permission to do things, you're essentially saying to someone "I don't trust you to make the right decision".
This lack of trust undermines an individual's confidence and fosters an environment of self-doubt. It contradicts the stoic belief in focusing on what we can control: our actions, our responses, and our integrity.
 <b> The true measure of self-worth comes from internal validation, not external approval.</b> Your self-worth is defined by your own standards and beliefs, not others' opinions or validation.</p>
 <p>If other people define the quality of your ideas and if your work is tied to what you define as important, then you're giving other people the power to influence your self-worth. Speaking from personal experience, this is not healthy, but
 if a company full of passionate people relies on approvals from seniority, then it is unknowingly placing employees in a position where they are constantly seeking external validation
    and are therefore unable to leverage their own work to build self-belief.</p>
    <p>In a workplace where autonomy is stifled, employees are denied the opportunity to challenge themselves, to grow, and to derive satisfaction from their work.
  It even stops them from manifesting who they truly are as individuals (if for example their work is a big part of their identity).</p>
<p>Trusting people to validate their own ideas not only accelerates innovation but also builds a culture of responsibility and self-respect.</p>
<p>When we remove the need for external validation, we empower individuals to pursue excellence for its own sake, fostering a resilient and motivated workforce that thrives on its own merit.</p>
<h3>How is this done in practice?</h3>
<hr></hr>
<p><b>At Servo, we are driven by action</b>. We believe in the power of experimentation and the value of learning from our mistakes.</p>
<p><b>We bake experimentation into our culture</b>. Companies become quite risk averse over time. The knee jerk response is to lock everything down and control everything, but this kills innovation.
 It is possible to have a culture of experimentation whilst also minimising risk. You just have to make sure that all experiments have a limited scope, are well defined, and have a clear objective.
 If you don't constrain variables, you often end up with a mess with no clear direction or clear takeaways for other stakeholders in the business.</p>
<p><b>We treat people like adults.</b> We respect boundaries and we trust our team to make the right decisions.</p>
<p>When you do this, you create a culture of trust and respect. You empower people to take ownership of their work and allow them to be industry leaders in their own right.</p>
<p>We will always be a company that values action over words. We will always be a company that values learning over perfection. We will always be a company that values autonomy over control. Let's just get on with it!</p>
<BlogFooter />

  </div>
);

const Post2 = () => (
  <div>
    <br />
    <h1><b><BlogHeader title="Flex is all about heat" author="James Twallin" /></b></h1>
    <hr></hr>
    <p>In the UK, a hidden energy giant lurks within our homes, devouring a staggering amount of energy and spewing carbon dioxide into the atmosphere.</p>
    <p>The majority of Britons are blissfully unaware of the energy requirements to keep their homes warm. Would it surprise you if I told you that over 80% of your energy use is in heating?</p>
    <Container style={{ width: '100%', marginTop: '2rem', marginBottom: '2rem', height: '200px' }}>
  <EnergyUseChart />
</Container>
    <p>The vast majority of UK homes get their heat from burning fossil fuels. Fossil fuel central heating directly contributes to climate change and degrades our air quality.</p>
    <p>Unlike with your energy use for electricity, the emissions happen directly at the point of use. <b>That's actually quite empowering if you think about it</b> because as consumers, we can have direct control to reduce our emissions.</p>
    <p>Fossil fuel boilers emit not just carbon dioxide, but also <a href="https://uk-air.defra.gov.uk/library/assets/documents/reports/aqeg/nitrogen_dioxide_in_the_UK-summary.pdf">nitrogen dioxide during combustion which is a leading cause of asthma.</a></p>
    <p>We must electrify our heating. We must stop burning stuff. It's very overlooked, but it's simply unavoidable if we are to meet our Net Zero obligations. Transportation and electricity are well on the way to becoming zero carbon sectors, and <b>heat will follow suit</b>.</p>
    <h3>We must be smart about heat</h3>
    <hr></hr>
    <p><b>Renewable energy</b> is variable but <b>very predictable</b>. We know when we'll have a lot of wind and sun, and when we won't; so we can plan for it.</p>
    <p>We have the ability to push demand around to match supply. This is the essence of flexibility. We can use the energy when it's available and avoid using it when it's not.</p>
    <p>As we've touched on, one side of 'flex' is when we have too much electricity supply and not enough demand to consume it, and the other side is when we have too much demand and not enough supply to go around.</p>
    <p>In a market for electricity, having too much supply manifests itself in negative pricing and having not enough supply manifests itself in high prices.</p>
    <Container>
      <br />
      <Row>
        <Col>
          <h3>Turn UP ↑ Events</h3>
          <hr></hr>
          <p><b>Why?</b> Too much supply/not enough demand</p>
          <p><b>What?</b> Get paid to use electricity</p>
        </Col>
        <Col>
          <h3>Turn DOWN ↓ Events</h3>
          <hr></hr>
          <p><b>Why?</b> Too little supply/too much demand</p>
          <p><b>What?</b> Pay more to use electricity</p>
        </Col>
      </Row>
    </Container>
    <br />
    <p>*there are several mechanisms to ensure supply, don't immediately think about blackouts. This is purely a discussion about price.</p>
    <h3>Why are electricity prices relevant to heat?</h3>
    <hr></hr>
    <p><b>Weather will drive the grid of the future</b>. It'll be the largest driver of supply. When heating becomes electrified, weather will be an even larger driver of demand.</p>
    <p>When it's cold, we need to heat our homes. The energy required to keep a home warm is determined by the temperature difference between the desired temperature indoors and the temperature outdoors.</p>
    <p>This is a typical home (9kW heat loss at -2°C) over the last few months (with 2 heating seasons). You can see how the heat demand changes with the weather:</p>
    <img src="heat_demand_colored_by_price.webp" width="100%" alt="heatmap" />
    <br />
    <br />
    <h5>Reflecting on the chart above, let's start with some statements:</h5>
    <Container>
      <li>In a future with electrified heating, we'll need more electricity when it's cold</li>
      <li><b>The UK's weather system is dominated by the jet stream</b>, which brings mild and windy weather from the Atlantic</li>
      <li><b>But...</b>when it's cold, it's usually because the jet stream has dipped south and we're getting still air from the continent</li>
    </Container>
    <br />
    <p>When it's cold, we'll need more electricity, precisely at the times when we generally have less renewable generation.</p>
    <p>But we need to think about using electricity the same way as we might think about using roads. Most of the time, our roads are empty. But at peak times, they're full. The same can be said for electricity infrastructure.</p>
    <p>By shifting energy use around, we can maximise the utility of the existing infrastructure and avoid the need to build more.</p>
    <p>I've plotted some data below as a heatmap, the top graph is the historical air temperature (in Northampton), the middle graph is the indicative wholesale electricity price, and the bottom graph is the grid's power generation from Wind and Solar.</p>
    <img src="weather_data_and_wholesale_prices_heatmap.webp" width="100%" alt="heatmap" />
    <br />
    <p>Offshore wind will help to solve a lot of this problem with its higher spatial diversity and higher capacity factors. Interconnectors will also deliver power from outside the weather system. It is also likely that our remaining fleet of gas peaker plants
    will be powered by green hydrogen in times of higher electricity demand. Relying on these alone is not enough. By far the most effective way to manage the grid is to manage demand.</p>
    <br />
    <h4>To conclude...</h4>
    <h3><b>Flex is all about heat</b></h3>
    <hr></hr>
    <p><b>Hogging the flex limelight are electric vehicles</b>, but the real elephant in the room is heating.</p>
    <Container>
      <li>Turn up events reward <b>inefficiency</b></li>
      <li>Turn down events reward <b>efficiency</b></li>
      <br />
    </Container>
    <p>If something is expensive you are incentivised to use less of it. Summarised: <b>efficiency wins</b></p>
    <p>What heating appliance is efficient and electric? <b>Heat pumps</b>.</p>
    <p>From my own experience of building heating optimisation engines in the past, I can tell you that optimisation of energy use for heating is a complex and challenging problem.</p>
    <p><b>It's not at all like turning an EV charge point on and off</b>, it's about focusing on the primary objective of keeping the home warm and comfortable (at the lowest cost).</p>
    <p>The prerequisite for that is a deep understanding of each home's heat loss characteristics and the ability to forecast future states.</p>
    <p>At Servo, we know heating <b>inside out</b>. We know how to optimise it, and we will make it work for you.</p>
    
    <BlogFooter />
  </div>
);

const Post3 = () => (
  <div>
    <br />
    <h1><b><BlogHeader title="July 2024: SEIS Advance Assurance and more" author="James Twallin" /></b></h1>
    <hr></hr>
    <Row>
    <p>It's been a while since I've written a blog post as we've been busy behind the scenes at Servo. 
      It's been a busy few months for us, and I'm excited to share some of the progress we've made.</p>
    </Row>
    <Row>
    <h2><b>The big one: SEIS Advance Assurance Granted</b></h2>
    <hr></hr>
    <p>Servo has been granted <b>SEIS Advance Assurance by HMRC. </b>
    SEIS (Seed Enterprise Investment Scheme) is a UK government scheme that offers tax relief to investors who invest in early-stage startups.</p>
    <p>The Advance Assurance means that investors can invest in Servo and claim tax relief under the SEIS scheme. 
      Servo is now eligible to issue SEIS shares to investors, which will help us raise the funding we need to grow the business.</p>
    <p>We're excited about the progress we've made and the opportunities that lie ahead. If you're interested in learning more about Servo or investing in our business, <b>please get in touch.</b> You can find James on LinkedIn.</p>
    <h2>Other Recent Achievements</h2>
    <hr/>
    </Row>
    <Row>
      <Col md={8}>
      <h3>Accepted for AWS Activate</h3>
    <p>We're thrilled to announce that Servo has been accepted into the AWS Activate program. AWS Activate is a program designed to 
      provide startups with the resources they need to get started on AWS.</p>
    <p>We received a large injection of AWS credits, which will help us scale our infrastructure and grow our capabilities.</p>
    <h3>Prototype Connected</h3>
    <p>We've also make some great progress on our prototype! The prototype is the first step to providing low cost and high quality data science services to size heating systems without using assumptions.</p>
    <p>James has been working hard to develop the prototype, and he's connected the first version of the installation tool to his gas boiler - the data being handled in
    real time by the Servo platform. A platform built by a data expert - meaning we can start delivering usable insights quickly.</p>
    <p>Already, it's possible to see the boiler cycling needlessly during the hot water cycle -  adjustments will be made!</p>
    <p>This is exciting because we're now on our way to developing something which can dramatically reduce the cost and inaccuracies associated with traditional methods for
      sizing heating systems.</p>
    </Col>
    <Col md={4} style={{textAlign: 'center'}}>
    {/* image center */}
    <img src="portal.png" alt="servo logo" height={400} style={{display: 'block', marginLeft: 'auto', marginRight: 'auto'}} />
    <p>A sneak peek at the Servo Portal</p>
    </Col>
    </Row>
    
    <BlogFooter />
  </div>
);


const Posts = [
  { id: 1, Component: Post1, link: 'execution', title: 'The Art of Execution: "How" Matters More Than "What"', image: 'windfarm.png' },
  { id: 2, Component: Post2, link: 'flex', title: 'Flex is all about heat', image: 'heat_demand_colored_by_price.webp' },
  { id: 3, Component: Post3, link: 'update', title: 'July 2024: SEIS Advance Assurance and more', image: 'portal.png' },
  // Add more post objects as needed
];

export default Posts;
