// Import necessary functions and configuration objects
import {Amplify} from 'aws-amplify';
import awsConfig from '../aws-exports'; // Adjust path as necessary


/**
 * Initialize AWS Amplify with the configuration from your aws-exports.js file.
 */
export function configureAmplify() {
  Amplify.configure(awsConfig);
}

