

const awsConfig = {
    aws_project_region: 'eu-west-2',
    aws_cognito_region: 'eu-west-2',
    aws_user_pools_id: process.env.REACT_APP_USER_POOLS_ID,
    aws_user_pools_web_client_id: process.env.REACT_APP_USER_POOLS_WEB_CLIENT_ID,
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',      
    aws_appsync_graphqlEndpoint: process.env.REACT_APP_GRAPHQL_URL,
    aws_appsync_region: 'eu-west-2',    
};


export default awsConfig;