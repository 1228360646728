import React from "react";
import { Container } from "react-bootstrap";

import SEOComponent from "../components/SEOComponent";

const Privacy = () => {

  const privacyPolicy = {
    websiteURL: "https://www.servo.energy",
    email: "contact@yourcompany.com",
    dataCollected: [
      "Usage Data (such as IP address, browser information, device information)",
      // Add more types of data collected by your website/application
    ],
    purposesOfCollection: [
      "To monitor and analyse website usage",
      // Add more purposes as applicable
    ],
    legalBasis: "We collect and process your data based on your consent and/or legitimate interests.",
    dataRetention: "We will retain your data only for as long as necessary for the purposes set out in this privacy policy.",
    dataSharing: "We will not share your information with third-party service providers.",
    userRights: [
      "Right to access: You have the right to request copies of your data.",
      "Right to rectification: You have the right to request that we correct any information you believe is inaccurate.",
      "Right to erasure: You have the right to request that we erase your data, under certain conditions.",
      // Include other user rights as per GDPR
    ],
    cookies: {
      useCookies: true,
      typesOfCookies: [
        "Essential Cookies: These cookies are necessary for the website to function properly.",
        "Analytical/Performance Cookies: These cookies allow us to analyze website usage and improve performance.",
        // Add more types of cookies used
      ],
      cookiePolicy: "By using our website, you consent to our use of cookies in accordance with our Cookie Policy.",
      cookieSettings: "You can manage your cookie preferences through your browser settings.",
    },
    securityMeasures: "We implement appropriate technical and organizational measures to ensure a level of security appropriate to the risk.",
    updatesToPolicy: "We may update our privacy policy from time to time. Any changes will be reflected on this page.",
    contactInfo: {
      name: "Your Name",
      position: "Privacy Officer",
      address: "Your Company Address",
      email: "privacy@yourcompany.com",
      phone: "Your Phone Number",
    },
  };

  return (
    <div>
    <SEOComponent title="Privacy Policy"
      description="Privacy Policy for Servo Energy"
      url="https://www.servo.energy/privacy"
      image="https://www.servo.energy/Servo logo Light@2x square.png"
    />
    <Container style={{ width: "80%", marginTop: "2rem", marginBottom: "2rem", fontSize: ".8rem" }}>
      <h5>Privacy Policy</h5>
      <p>
        This privacy policy will explain how our organisation uses the data we collect from you when you use our website.
      </p>
      <h5>Topics:</h5>
      <ul>
        {privacyPolicy.dataCollected.map((data, index) => (
          <li key={index}>{data}</li>
        ))}
      </ul>
      <h5>Purposes of Collection:</h5>
      <ul>
        {privacyPolicy.purposesOfCollection.map((purpose, index) => (
          <li key={index}>{purpose}</li>
        ))}
      </ul>
      <h5>User Rights:</h5>
      <ul>
        {privacyPolicy.userRights.map((right, index) => (
          <li key={index}>{right}</li>
        ))}
      </ul>
      <h5>Cookies:</h5>
      <p>{privacyPolicy.cookies.cookiePolicy}</p>
      <p>{privacyPolicy.cookies.cookieSettings}</p>
      <ul>
        {privacyPolicy.cookies.typesOfCookies.map((cookie, index) => (
          <li key={index}>{cookie}</li>
        ))}
      </ul>
      <p>{privacyPolicy.updatesToPolicy}</p>
    </Container>
    </div>
    
  );
};

export default Privacy;
