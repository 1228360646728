import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Footer.css';

// Define the site map structure
const siteMapSections = [
  {
    title: 'Company',
    links: [
      { name: 'About Us', path: '/about' },
      { name: 'Blog', path: '/blog' },
    ],
  },
  {
    title: 'Product',
    links: [
      { name: 'Heat Pump Control', path: '/product' },
      { name: 'Thermal Optimisation', path: '/product' },
    ],
  },
  {
    title: 'Legal',
    links: [
      { name: 'Terms of Service', path: '/terms' },
      { name: 'Privacy Policy', path: '/privacy' },
    ],
  },
];

const Footer = () => {
  return (
    <footer className="custom-footer">
      <Container>

        <Row className="mb-4" style={{ justifyContent: 'center' }}>
          {siteMapSections.map((section, index) => (
            <Col md={3} key={index}>
              <h5>{section.title}</h5>
              <ul>
                {section.links.map((link, linkIndex) => (
                  <li key={linkIndex}><a href={link.path}>{link.name}</a></li>
                ))}
              </ul>
            </Col>
          ))}
        </Row>
        <Row>
          <Col className="text-center">
     
            <span>© Copyright 2024 Servo Energy - All rights reserved. Company No. 15664690</span>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
