import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const EnergyUseChart = () => {
  const options = {
    indexAxis: 'y', // Horizontal chart
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom', // Adjusted for mobile layout
        font:
        {
          size: 16,
          family: 'Raleway',
        },
      },
      title: {
        display: true,
        text: 'Typical Annual Household Energy Use (kWh)',
        font: {
          size: 20,
          family: 'Raleway',
        },
      },
    },
    scales: {
      x: {
        stacked: true, // Enables stacking
      },
      y: {
        stacked: true, // Enables stacking for horizontal layout
      },
    },
    // don't fix the aspect ratio
    maintainAspectRatio: false,
  };

  const labels = [''];

  const data = {
    labels,
    datasets: [
      {
        label: 'Heating (kWh)',
        data: [11500],
        backgroundColor: 'rgba(210, 105, 30, 0.5)', // Terracotta color
      },
      {
        label: 'Electricity (kWh)',
        data: [2700],
        backgroundColor: 'rgba(65, 105, 225, 0.5)', // Royal/Rustic blue color
      },
    ],
  };

  // reduce the max height
  return  <Bar options={options} data={data} />

};
