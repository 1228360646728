import React from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import SEOComponent from '../components/SEOComponent';
import { getData, getDevices, getDevice, getPower} from '../graphql/queries';
import { generateClient } from '@aws-amplify/api';
import { graphqlOperation } from '@aws-amplify/api-graphql';
import { useState } from 'react';
import FlowReturnLineChart from '../components/chart_components/FlowReturnLineChart';
import RoomTempLineChart from '../components/chart_components/RoomTempLineChart';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { useEffect } from 'react';
import { signOut} from '@aws-amplify/auth';


import SiteCard from '../components/SiteCard';

// Initialize the GraphQL client
const client = generateClient();

    
// Asynchronous function to fetch prediction data
async function fetchData(device_id, date) {
    try {
        // assert that the center and the input data are the same
        const response = await client.graphql(graphqlOperation(getData, { device_id , date }));
    
        console.log("Data fetched:", response);
        return response.data.getData;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;  // Propagate the error to be handled in the caller function
    }
}

// Asynchronous function to fetch prediction data
async function fetchHeatPower(device_id, date) {
    try {
        // assert that the center and the input data are the same
        console.log("fetching power data for device_id:", device_id, "date:", date);
        const response = await client.graphql(graphqlOperation(getPower, { device_id , date }));
    
        console.log("Data fetched:", response);
        return response.data.getPower;
    } catch (error) {
        console.error("Error fetching getPower:", error);
        throw error;  // Propagate the error to be handled in the caller function
    }
}

async function fetchDevices() {
    try {
        // assert that the center and the input data are the same
        const response = await client
            .graphql(graphqlOperation(getDevices))

        console.log("Data fetched:", response);
        return response.data.getDevices;
    }
    catch (error) {
        console.error("Error fetching devices:", error);
        throw error;  // Propagate the error to be handled in the caller function
    }
}

async function fetchDeviceInfo(device_id) {
    try {
        // assert that the center and the input data are the same
        const response = await client
            .graphql(graphqlOperation(getDevice, { device_id }))
        console.log("Data fetched:", response);
        return response.data.getDevice;
    }
    catch (error) {
        console.error("Error fetching device info:", error);
        throw error;  // Propagate the error to be handled in the caller function
    }
}



const DataPage = () => {
    const [data, setData] = useState([]);
    const [powerData, setPowerData] = useState([]);
    const [devices, setDevices] = useState([]);
    const [selectedDeviceId, setSelectedDeviceId] = useState(null);
    const [deviceInfo, setDeviceInfo] = useState(null); // [device_id, friendly_name, description
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);

    useEffect(() => {
        const fetchDevicesfunc = async () => {
            try {
                const devices = await fetchDevices();
                console.log("Devices fetched:", devices);
                setDevices(devices);
            } catch (error) {
                console.error("Error fetching devices:", error);
            }
        };

        fetchDevicesfunc();
    }, []);

    useEffect(() => {
        const fetchDeviceInfofunc = async () => {  
            try {
                const deviceInfo = await fetchDeviceInfo(selectedDeviceId);
                console.log("Device Info fetched:", deviceInfo);
                setDeviceInfo(deviceInfo);
            } catch (error) {
                console.error("Error fetching device info:", error);
            }
        }

        if (selectedDeviceId) {
            fetchDeviceInfofunc();
        }

        // set the data as an empty array
        setData([]);
    
    }, [selectedDeviceId]);


    // date picker
    const handleDateChange = (date) => {
        // set as a string YYYY-mm-dd
        console.log("Date selected:", date.toString());

        setSelectedDate(date.toString());
        // get the data for the selected dat
    }


    const fetchDatafunc = async () => {
        if (!selectedDeviceId) {
            console.error("No device selected");
            return;
        }
        try {
            const data = await fetchData(selectedDeviceId, selectedDate);
            console.log("Data fetched:", data);
            setData(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const fetchPowerDatafunc = async () => {
        if (!selectedDeviceId) {
            console.error("No device selected");
            return;
        }
        try {
            const powerData = await fetchHeatPower(selectedDeviceId, selectedDate);
            console.log("Data fetched:", powerData);
            setPowerData(powerData);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    return (
        <Authenticator>
            {({ user }) => (
                <Container>
                <p>I think it's best to have all the data in one place, as in on one scrollable page.
                    This way, the user can see all the data at once, and also it makes it easier to manage the data flow on the 
                    page.
                </p>

                <Row>
                    <Col>
                    <SiteCard title={`Main Takeaways`} headerColor="#fce59c" header="Data Portal" content={
                        <p>
                            This page is used to view data from the devices. 
                        </p>
                    }>
                    </SiteCard>
                    
                    </Col>
                    
                    <Col>
                    <SiteCard title={`Device Information`} headerColor="#fce59c" header="Here's the key information for the device" content={
                          <Row style={{ textAlign: 'center' }}>
                    <Col>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Device ID</td>
                                {/* select */}
                                <td>
                                    <select onChange={(e) => setSelectedDeviceId(e.target.value)}>
                                        <option value="">Select a device</option>
                                        {devices.map(device => (
                                            <option key={device.device_id} value={device.device_id}>{device.device_id}</option>
                                        ))}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>Friendly Name</td>
                                <td>{deviceInfo?.friendly_name}</td>
                            </tr>
                            <tr>
                                <td>Description</td>
                                <td>{deviceInfo?.description}</td>
                            </tr>
                        </tbody>
                    </Table>


                    </Col>
                    </Row>
                    
                    }>
                    </SiteCard>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                <SiteCard title={`Charts`} headerColor="#fce59c" header="Charts" content={
                <>
                <Row style={{ textAlign: 'center', width: '75%', margin: 'auto' }}>

                    {/* also a date picker */}
                    {/* set the default as today */}
                    <Col>
                    <input type="date" onChange={(e) => handleDateChange(e.target.value)}
                        value={selectedDate || new Date().toISOString().split('T')[0]} />
                    </Col>
                    <Col>
                    
                    <button onClick={() => { fetchDatafunc(); fetchPowerDatafunc(); }}>Fetch Data</button>

                    </Col>
                </Row>
                <Row style={{ textAlign: 'center' , marginTop: '1rem'}}>
                <h3>Flow and Return Temperatures</h3>

                    <FlowReturnLineChart data={data} powerdata={powerData} />
                </Row>

                <Row style={{ textAlign: 'center', marginTop: '1rem' }}>
                <h3>Room Temperature and Humidity</h3>

                    <RoomTempLineChart data={data} />
                    
                </Row>

                </>
                }>
                </SiteCard>
                </Col>
                </Row>
            
            {/* signout */}
            <Row style={{ textAlign: 'center', marginTop: '1rem' }}>
                <Col>
                <button onClick={() => signOut()}>Sign Out</button>
                </Col>
            </Row>
            </Container>


            )}


        </Authenticator>
    );
};

// DataPortal component which includes SEO and MemberPage components
export function DataPortal() {
    return (
        <>
            <SEOComponent 
                title="Data Portal" 
                description="Data Portal for viewing and managing data"
                url="https://www.servo.energy/data-portal"
                image="https://www.servo.energy/Servo logo Light@2x square.png"
            />
            <DataPage />
        </>
    );
}

export default DataPortal;

