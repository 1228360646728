// Code for the Trends page
import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';
import LandingHeader from '../components/LandingHeader';
import SEOComponent from '../components/SEOComponent';

import '../styles.css';

// factoid function
function Factoid() {
  return (
<Container style={{ width: '100%', marginTop: '3rem', marginBottom: '3rem' , minHeight: '70vh'}}>
  <Row className="gy-3 gy-md-5 gy-lg-0 align-items-center">
    <Col xs={12} lg={5}>
      <h1 className="display-5 mb-3 mb-xl-4">Decarbonising heat should be easier!</h1>
    </Col>
    <Col xs={12} lg={7}>
      <Row className="justify-content-xl-end">
        <Col xs={12} xl={11}>
          <Row className="gy-3 gy-md-4">
            <Col xs={12} sm={6}>
              <div className="card border-0 border-bottom border-dark" style={{boxShadow: '0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125)'}}>
                <div className="card-body text-center p-4 p-xxl-5">
                  <h3 className="display-1 mb-2">~85%</h3>
                  <p className="fs-5 mb-0 text-secondary">UK homes use fossil heating</p>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={6}>
              <div className="card border-0 border-bottom border-dark" style={{boxShadow: '0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125)'}}>
                <div className="card-body text-center p-4 p-xxl-5">
                  <h3 className="display-1 mb-2">18%</h3>
                  <p className="fs-5 mb-0 text-secondary">of UK emissions from heating</p>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={6}>
              <div className="card border-0 border-bottom border-dark" style={{boxShadow: '0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125)'}}>
                <div className="card-body text-center p-4 p-xxl-5">
                  <h3 className="display-1 mb-2">~80%</h3>
                  <p className="fs-5 mb-0 text-secondary">UK Household energy use is heating</p>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={6}>
              <div className="card border-0 border-bottom border-dark" style={{boxShadow: '0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125)'}}>
                <div className="card-body text-center p-4 p-xxl-5">
                  <h3 className="display-1 mb-2">1.7m</h3>
                  <p className="fs-5 mb-0 text-secondary">heat pumps needed yearly for Net Zero</p>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  </Row>
</Container>

  );
}


const About = () => {
  return (
<div>

  <SEOComponent title="About"
    description="Welcome to the About Page"
    url="https://www.servo.energy/about"
    image="https://www.servo.energy/Servo logo Light@2x square.png"
  />

  <LandingHeader title="About" />
  <Factoid />
  {/* <ProblemStatement /> */}

<div style={{ backgroundColor: 'black', color: 'white', display: 'flex', flexDirection: 'column', padding: '2rem' }}>
  <Container style={{ width: '100%', marginTop: '2rem', marginBottom: '2rem' }}>
    <Row className="justify-content-md-center">
      <Col md={6}>
        <h4>Let's face it...</h4>
        <h1>Without the right tools, it's impossible to do a job well</h1>
      </Col>
      <Col md={6}>
        <h4 >Current heat loss calculators are:</h4>
        <br />
        <ul >
          <li>Too complex</li>
          <li>Not data driven</li>
          <li>Too inaccurate</li>
          <li>Narrowly designed for the Boiler Upgrade Scheme ignoring customer needs or habits</li>
        </ul>
      </Col>
    </Row>
  </Container>
</div>

<Container style={{ padding: '2rem' }}>
  <Row className="justify-content-md-center">
    <Col md={6}>
      <h4>Let's decarbonise heat faster</h4>
      <h1>Servø is here to make clean heat simple</h1>
      <h4 style={{ textAlign: 'left', margin: 'auto', display: 'inline-block' }}>Our tools will be:</h4>
      <br />
      <br />
      <ul style={{ textAlign: 'left', margin: 'auto', display: 'inline-block' }}>
        <li>Simple to use</li>
        <li>Data driven</li>
        <li>Accurate</li>
        <li>Designed with the customer in mind</li>
      </ul>
    </Col>
    <Col sm={6} md={6}>
      <br />
      <Container style={{ width: '100%', marginTop: '2rem', marginBottom: '2rem', textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
        <img src="puzzle.png" style={{ maxWidth: '80%', height: 'auto' }} alt="Servo logo" />
      </Container>
    </Col>
  </Row>
</Container>



</div>


  );
}

export default About;
