// Code for the Trends page
import React from 'react';
import { Container } from 'react-bootstrap';



import LandingHeader from '../components/LandingHeader';
import Timeline from '../components/Timeline';

import SEOComponent from '../components/SEOComponent';





const Product = () => {
  // example page

  return (
    // container
    <div>

      <SEOComponent title="Product"
        description="Welcome to the Product Page"
        url="https://www.servo.energy/product"  
        image="https://www.servo.energy/Servo logo Light@2x square.png"
      />

      
                <LandingHeader title="Product" />
    <Container style={{ width: '100%', marginTop: '2rem', marginBottom: '2rem' }}>
        <Timeline />
        
    </Container>
    </div>

  );
}

export default Product;