import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

import SEOComponent from '../components/SEOComponent';
import './Home.css';
import '../styles.css';

const Home = () => {
  return (
    <div>

      <SEOComponent title="Home"

        description="Welcome to the Home Page"  
        url="https://www.servo.energy/home"
        image="https://www.servo.energy/Servo logo Light@2x square.png"

      />
      <Container style={{ width: '100%', minHeight: '100vh' }}>
        <Container style={{ width: '100%', marginTop: '2rem'}}>
          <Row className="d-flex justify-content-center g-5" style={{marginBottom: '2rem' }}>
            <Col md={6} className="d-flex flex-column justify-content-center">
              <h1>At servø, we're on a mission to make clean heat simple</h1>
              <h2>Heat needs to be simple, efficient, and sustainable. We're making that happen.</h2>
              <br />
              <br />
              <Button variant="dark" size="lg" style={{ width: '60%', backgroundColor: 'black', fontWeight: 200}} href="/about">Coming Soon...</Button>
            </Col>
            <Col md={6} className="d-flex flex-column justify-content-center">
              <img src="funimage.png" width="85%" alt="funimage" height="auto" position="relative" />
            </Col>
          
          </Row>

          <br />
          <Row className='d-flex justify-content-between g-3' style={{marginBottom: '2rem' }}>
         
          <Col md={4} sm={12} >
            <div className="wave-container">
              <div className="wave"></div>
            </div>
            <h3>Heat Loss Surveys</h3>
            <p>Sizing heat pumps is a breeze with our data-driven heat loss tooling. Slap it on an existing boiler and we'll deliver the insights you need to size a heat pump.</p>
          </Col>
          <Col md={4} sm={12}>
            <div className="wave-container">
              <div className="wave"></div>
            </div>
            <h3>Heat Pump Control</h3>
            <p>Our industry-leading experts have built their careers on heating and thermal storage. We're now building the future of smart heat.</p>
          </Col>
          <Col md={4} sm={12}>
            <div className="wave-container">
              <div className="wave"></div>
            </div>
            <h3>Flex from the Beginning</h3>
            <p>The future of heat is all about flexibility. We're building it in from the start.</p>
          </Col>
        </Row>
        


        </Container>
      </Container>



      <div style={{ backgroundColor: 'black', color: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: '2rem' }}>
        <h1>ø</h1>
        <h2>Get on the cutting edge of heat</h2>
        <p style={{ margin: 0, width: '100%' }}><a href="/about" style={
          { color: '#ffdd00', textDecoration: 'underline', fontSize: '1.2rem' }
        }>Coming Soon...</a></p>
        <br />
        <br />
      </div>

      <Container style={{ width: '100%', marginTop: '2rem', marginBottom: '2rem' }}>
        <Container style={{ width: '100%', marginTop: '5rem', marginBottom: '5rem' }}>
          <Row className="d-flex justify-content-center g-5">
            <Col md={6} className="d-flex flex-column justify-content-center">
              <h1 style={{ fontSize: '3rem'}}>clean heat made simple</h1>
              <h2>building tools to accelerate the transition to clean heat</h2>
              <br />
              <br />
              <Button variant="dark" size="lg" style={{ width: '60%', backgroundColor: 'black', fontWeight: 200}}>Coming Soon...</Button>
            </Col>
            <Col md={6} className="d-flex flex-column justify-content-center">
              <img src="windfarm.png" width="80%" alt="Turbines" />
            </Col>
            
          </Row>



        </Container>
        <Row className="d-flex align-items-center justify-content-center g-5" style={{ marginBottom: '2rem', textAlign: 'center' }}>

<Col md={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
<Container>
<div className="wave-container">
              <div className="wave"></div>
            </div>
<h4>Supported by</h4>
  <img src="aws_activate.png" alt="AWS Activate logo" height={50}/>
  <div className="wave-container">
              <div className="wave"></div>
            </div>

</Container>

</Col>

</Row>


      </Container>
      

    
    
    </div>
  );
}

export default Home;
