import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto'; // Automatically register the controllers
import 'chartjs-adapter-date-fns'; // import the adapter
import { enGB } from 'date-fns/locale'; // Import the locale from date-fns

const RoomTempLineChart = ({ data }) => {
    const chartData = {
        labels: data.map(item => item.time), // Extract labels from data
        datasets: [

            {
                label: 'Room Temperature',
                data: data.map(item => item.room_temperature), // Extract values from data
                fill: false,
                pointRadius: 0,

                backgroundColor: 'rgba(192,192,75,0.2)',
                borderColor: 'rgba(192,192,75,1)'
            },
            {
                label: 'Room Humidity',
                data: data.map(item => item.room_humidity), // Extract values from data
                fill: false,
                pointRadius: 0,
                backgroundColor: 'rgba(192,75,75,0.2)',
                borderColor: 'rgba(192,75,75,1)'
            }

        ]
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'top'
            }
        },
        scales: {
            x: {
                type: 'time',
                adapters: {
                    date: {
                        locale: enGB // Using the English (Great Britain) locale from date-fns
                    }
                },
                time: {
                    parser: 'yyyy-MM-dd\'T\'HH:mm:ssX', // X for the ISO 8601 string including timezone
                    tooltipFormat: 'dd MMM yyyy HH:mm', // format for the tooltip
                    unit: 'hour', // adjust based on your data density (minute, hour, day)
                    displayFormats: {
                        // 01 Jun 2021
                        day: 'dd MMM yyyy'
                    }
                },
                ticks: {
                    callback: function(value, index, values) {
                        // This callback will format the tick labels
                        const date = new Date(value);
                        return new Intl.DateTimeFormat('en-GB', {
                            timeZone: 'Europe/London',
                            year: 'numeric', month: 'short', day: 'numeric',
                            hour: '2-digit', minute: '2-digit', hour12: false
                        }).format(date);
                    }
                }
            },
            y: {
                // beginAtZero: true
                // set the min and max values for the y-axis
                suggestedMin: Math.min(...data.map(item => item.return_temperature)) - 5,
                suggestedMax: Math.max(...data.map(item => item.flow_temperature)) + 5


            }
        }
    } 

    return <Line data={chartData} options={chartOptions} />;
};

export default RoomTempLineChart;
