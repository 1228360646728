// src/pages/Blog.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import LandingHeader from '../components/LandingHeader';
import BlogCard from '../components/BlogCard';
import SEOComponent from '../components/SEOComponent';

import blogPosts from '../blog/BlogPosts';


const Blog = () => {

  // sort the blogposts backwards
  blogPosts.sort((a, b) => b.id - a.id);
  return (
    <div>

      <SEOComponent title="Blog"
        description="Welcome to the Blog Page"
        url="https://www.servo.energy/blog"
        image="https://www.servo.energy/Servo logo Light@2x square.png"
      />
      <LandingHeader title="Blog" />
      <Container style={{ marginTop: '2rem', marginBottom: '2rem' }}>
        <Row>
          {blogPosts.map(blog => (
            <Col sm={12} md={6} lg={4} key={blog.id}> {/* Adjust column sizes as needed */}
              <BlogCard
                title={blog.title}
                summary={blog.content} // Adjust according to your BlogCard's props
                link={`/${blog.link}`}
                image={blog.image}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Blog;
