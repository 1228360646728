import React from 'react';
import './SiteCard.css'; // Importing the CSS file
import { Container } from 'react-bootstrap';

const SiteCard = ({header, title, content, headerColor= '#f8ca33'}) => {
  return (
    <div className="news-card">
      <div className="header" style={{ backgroundColor: headerColor }}>
        {header}
      </div>
      <div className="title">
        {title && (
          <>
            <Container>
              <h3>{title}</h3>
            </Container>
            <hr style={{ backgroundColor: 'black', height: '1px', border: 'none', width: '50%', marginLeft: 'auto', marginRight: 'auto' }} />
          </>
        )}
      </div>
      <div className="content">
        {/* left and right padding */}
        <Container style={{ paddingLeft: '1rem', paddingRight: '1rem', paddingTop: '1rem', paddingBottom: '1rem' }}>
        {content}
        </Container>
      </div>
      {/* <div className="imagesContainer">
        <img src="/path/to/image1.jpg" className="image" alt="Campaign Thank You Message" />
        <img src="/path/to/image2.jpg" className="image" alt="Party Leader Speech" />
        <img src="/path/to/image3.jpg" className="image" alt="Election Rally" />
      </div> */}
    </div>
  );
};

export default SiteCard;
