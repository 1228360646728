import React from 'react';
import { Nav, Navbar as BootstrapNavbar, Container } from 'react-bootstrap';
import './Navbar.css'; // Make sure this file contains your updated styles
import '../styles.css'; // Make sure this file contains your updated styles

import { LinkContainer } from 'react-router-bootstrap';

const Navbar = () => {
  return (
    <BootstrapNavbar className='custom-navbar' expand='lg' variant='light'>
      <Container style={{ width: '100%' }}>
        <BootstrapNavbar.Brand href="/home" className="custom-nav-link content-3">
          <img src="Servo Logo @1x.png" width="125" className="d-inline-block align-top" alt="Servo logo" />
        </BootstrapNavbar.Brand>
        <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
        <BootstrapNavbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <LinkContainer to="/about">
              <Nav.Link className="custom-nav-link content-3">About</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/product">
              <Nav.Link className="custom-nav-link content-3">Product</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/blog">
              <Nav.Link className="custom-nav-link content-3">Blog</Nav.Link>
            </LinkContainer>

          </Nav>
        </BootstrapNavbar.Collapse>
      </Container>
    </BootstrapNavbar>
  );
};

export default Navbar;
