import './App.css'; // This line imports the CSS file.
// import './styles.css';

import React from 'react';
import CookieConsent from "react-cookie-consent";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import '@aws-amplify/ui-react/styles.css';

import Navbar from "./components/Navbar";
import Home from './pages/Home';
import About from './pages/About';

import Product from './pages/Product';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import Privacy from './pages/Privacy';
import DataPortal from './pages/DataPortal';


import Footer from './components/Footer';

function App() {

  const props = {
    style: {color: "#4e503b", backgroundColor: "#f0f0f0", border: "1px solid #4e503b", borderRadius: "5px",
    padding: "5px 10px", margin: "5px"}
  }
  return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', width: '100%' }}>
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          declineButtonText="Decline" // Text for the decline button
          enableDeclineButton={true} // Enable the decline button
          cookieName="ServoCookieConsent" // Name of the cookie
          style={{ background: "#2B373B" }}
          customButtonProps = {props}
          customDeclineButtonProps = {props}
          expires={150}
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>



          <Router>
  
            <Navbar />
            <div style={{ flex: 1 }}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/product" element={<Product />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/:postId" element={<BlogPost />} />
                <Route path="/data-portal" element={<DataPortal />} />

                <Route path="*" element={<p>Page not found</p>} /> {/* Fallback Route */}
                </Routes>
            </div>
            <Footer />
          </Router>
          </div>
      )
}

export default App;
