import React from 'react';
import { Card, Button } from 'react-bootstrap';
import './BlogCard.css';

const BlogCard = ({ title, summary, link, image }) => (
  <Card className="blogCard"> {/* Use className for styling */}
    {image && (
      <div className="cardImageContainer">
        <img src={image} alt="Blog" />
      </div>
    )}
    <Card.Body className="cardBody">
      <Card.Title>{title}</Card.Title>
      <Button variant="dark" href={link} className="readMoreButton">Read More</Button>

    </Card.Body>
    
  </Card>
);

export default BlogCard;
